import styled from 'styled-components';

export const Spinning = styled.span`
	width: 100%;
	height: 100%;
	min-height: 20vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	z-index: 1000;
	background: var(--background);
`;

export const Loading = styled.div`
	width: 80px !important;
	height: 50px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		width: 4rem !important;
		color: var(--text);
		path,
		circle {
			fill: var(--text) !important;
		}
	}
`;

export const Loader = styled.span`
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(80, 27, 240, 1) 25%,
		rgba(237, 156, 57, 1) 49%,
		rgba(229, 58, 201, 1) 73%,
		rgba(0, 0, 0, 0) 100%
	);
	border-radius: 50px;
	display: block;
	height: 0.3rem;
	max-width: 80px !important;
	bottom: 0;
	position: absolute;
	transform: translateX(64px);
	animation: loading 3.5s ease both infinite;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background: black;
		border-radius: inherit;
		animation: loading2 3.5s ease both infinite;
	}

	@keyframes loading {
		0% {
			width: 16px;
			transform: translateX(0px);
		}

		40% {
			width: 100%;
			transform: translateX(0px);
		}

		80% {
			width: 16px;
			transform: translateX(64px);
		}

		90% {
			width: 100%;
			transform: translateX(0px);
		}

		100% {
			width: 16px;
			transform: translateX(0px);
		}
	}
	@keyframes loading2 {
		0% {
			transform: translateX(0px);
			width: 16px;
		}

		40% {
			transform: translateX(0%);
			width: 80%;
		}

		80% {
			width: 100%;
			transform: translateX(0px);
		}

		90% {
			width: 80%;
			transform: translateX(15px);
		}
		100% {
			transform: translateX(0px);
			width: 16px;
		}
	}
`;
