// @ts-nocheck
import Teste from 'components/Teste';
import Upsell from 'components/Upsell';
import UpsellDiscountModal from 'components/Upsell/DiscountModal';
import { useFlow } from 'contexts/FlowContext';
import { useOneClick } from 'contexts/OneClickBuyContext';
import { useEffect } from 'react';
import './i18n/index';

const OneClickApp = () => {
	const { setIsModalOpen, fetch, decline, track, setOffer } = useOneClick();
	const {
		flow,
		getFlowUUID,
		setIsLoading: setIsLoadingFlow,
		getStepOfferCode,
		findStep,
		step,
		findOne: findFlow,
		handleRejectFlow
	} = useFlow();
	const isProduction = process.env.REACT_APP_MIDAS_ENVIRONMENT === 'production';

	const flowUUID = getFlowUUID();

	const checkOfferCode = async offerCode => {
		if (flow) {
			offerCode = step?.code;
		} else {
			if (!isProduction) {
				offerCode = document.getElementById('dev_offer_id').value;
				track(offerCode);
			}

			if (!offerCode) {
				console.log('TICTO: Não foi encontrada uma oferta para o seu botão de upsell.');
				return;
			}
		}

		return offerCode;
	};

	const execute = async offerCode => {
		offerCode = await checkOfferCode(offerCode);
		setIsModalOpen(true);
		await fetch(offerCode);
	};

	const reject = async () => {
		if (flowUUID) {
			await handleRejectFlow();
		} else {
			await decline(true);
		}
	};

	const addCheckoutBuilderListeners = async (accept, reject) => {
		for (let i = 0; i < accept?.length; i++) {
			accept[i].addEventListener('click', e => execute(e.target.dataset.offerCode));
		}

		for (let i = 0; i < reject?.length; i++) {
			reject[i].addEventListener('click', e => decline(true));
		}
	};

	const addFlowListeners = async (accept, reject) => {
		const stepOfferCode = getStepOfferCode();
		for (let i = 0; i < accept?.length; i++) {
			accept[i].addEventListener('click', e => execute(stepOfferCode));
		}

		for (let i = 0; i < reject?.length; i++) {
			reject[i].addEventListener('click', (e) => handleRejectFlow())
		}
	};

	useEffect(() => {
		if (isProduction) {
			const accept = document.querySelectorAll('.ticto-upsell-button');
			const reject = document.querySelectorAll('.ticto-refuse-button');

			if (!document.querySelectorAll('.ticto-upsell-button')) {
				alert(
					'TICTO: Não encontramos o botão de upsell em sua página. insira o botão que informamos e tente novamente'
				);
				return;
			}

			if (flowUUID) {
				addFlowListeners(accept, reject);
			} else {
				addCheckoutBuilderListeners(accept, reject);
			}
		}

		if (flowUUID) {
			let stepOfferCode = getStepOfferCode();
			findFlow(flowUUID).then(flow => {
				findStep(flow?.product?.offers[0]?.code, stepOfferCode);
			});
		} else {
			setIsLoadingFlow(false);
		}
	}, []);

	return (
		<>
			{!isProduction && <Teste execute={execute} reject={reject} flowUUID={flowUUID} step={step} />}
			<Upsell />
			<UpsellDiscountModal />
		</>
	);
};

export default OneClickApp;
