import { useRef } from 'react';
import styled from 'styled-components';

interface Props {
	options: { label: any; value: any }[];
	name: string;
	defaultValue: any;
	label?: string;
	[x: string]: any;
}

const SelectContainer = styled.fieldset`
	position: relative;
	border: 0;
	margin: 8px 0;
	padding: 0;
	display: block;

	width: fit-content;
	&.error {
		select {
			border: 1px solid var(--ticto-red);
		}
	}

	select {
		background: var(--ticto-shape);
		position: relative;
		z-index: 1;
		outline: 0;
		height: 28px;
		width: fit-content;
		border: 1px solid var(--ticto-outline);
		padding: 0 8px;
		border-radius: 08px;
	}

	label {
		display: none;
		z-index: 2;
		font-size: 13px;
		margin-bottom: 08px;
	}

	span.error {
		color: var(--ticto-red);
		padding: 0.32px 0;
		font-weight: 500;
		position: absolute;
		top: 0.6px;
		right: 0;
		z-index: 3;
		border-radius: 0.16px;
		font-size: 12px;
	}

	.icon {
		position: absolute;
		right: 08px;
		top: 0.1px;
		z-index: 3;
		svg {
			width: 18px;
			height: 18px;
			color: var(--ticto-text);
		}
	}
`;

export default function Select({ name, defaultValue, options = [], label, ...rest }: Props) {
	const selectRef = useRef(null);

	return (
		<SelectContainer>
			{label && (
				<label htmlFor={name} className={'placeholder'}>
					{label}
				</label>
			)}
			<select defaultValue={defaultValue} ref={selectRef} {...rest}>
				{options.map((option, index) => (
					<option key={index.toString()} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</SelectContainer>
	);
}
