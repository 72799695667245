import styled from 'styled-components';
export const PixCountdownElements = styled.ul`
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	padding: 0 18px !important;
	margin: 0 !important;
	height: 3rem !important;

	li {
		display: flex !important;
		align-items: center !important;
		justify-content: center;
		height: 3rem !important;
		border-radius: 8px !important;
		list-style: none !important;
		background: #fff !important;
        padding: 0 18px;
        margin: 0.5rem 0;
        font-size: 13px !important;
        /* width: auto; */
		color: #000000 !important;

		div:first-child {
			width: auto;
			margin-right: 0.5rem;
		}

		svg {
			width: 20px !important;
		}

		.number {
			max-width: 2rem;
			padding: 0.3rem;
			background-color: #e73f5d;
			border-radius: 3px;
			color: #fff;
			margin: 0 0.2rem !important;
			text-align: center;
			font-weight: 600;
		}
	}
`;
