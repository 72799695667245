import styled from 'styled-components';

export const HeaderContainer = styled.div`
	svg {
		color: white !important;
	}
`

export const DiscountHeaderContainer = styled.div`
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	position: relative !important;
	height: 50px !important;
	gap: 16px !important;
	padding: 0 18px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: bold !important;
	color: white !important;
	background: var(--ticto-red) !important;
	margin: 0 !important;
`
