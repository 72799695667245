import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
font-size: 16px !important;

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

:root {
	--ticto-bg-primary: var(--ticto-primary);
	--ticto-bg-secondary: var(--ticto-secondary);
	--ticto-white: #ffffff;
	--ticto-font: "Inter", Helvetica, Arial, sans-serif;

	--ticto-background: #f3f6fe;
	--ticto-title: #2d3748;
	--ticto-text: #252525;
	--ticto-text-light: #a0aec0;
	--ticto-shape: #ffffff;
	--ticto-shape-dark: #f7f7f7;

	--ticto-input-border: #e2e6e9;
	--ticto-input-bg: rgba(0, 0, 0, 0.02);
	--ticto-outline: #dddddd;

	--ticto-primary: #131313;
	--ticto-primary-hover: #6856d1;
	--ticto-primary-light: #c9c0f8;

	--ticto-secondary: #fad661;
	--ticto-secondary-hover: rgb(238, 225, 150);
	--ticto-secondary-light: #fff9da;

	--ticto-success: #30d371;
	--ticto-success-hover: #33cd9c;
	--ticto-success-light: #2bd29d;

	--ticto-warning: #d69e2e;
	--ticto-warning-hover: #ecc94b;
	--ticto-warning-light: #fffade;

	--ticto-info: #00b5d8;
	--ticto-info-hover: #0bc5ea;
	--ticto-info-light: #c5f6ff;

	--ticto-danger: #c53030;
	--ticto-danger-hover: #e53e3e;
	--ticto-danger-light: #ffcccc;

	--ticto-green: #2ead87;
	--ticto-green-light: #C8E6C9;
	--ticto-red: #e73f5d;
	--ticto-red-light: #fc8181;
	--ticto-orange: #e7743f;

	--ticto-ob-outline: #efe05e;
	--ticto-ob-title-bg: var(--red); //#ffbd7c;
	--ticto-ob-bg: #fffbe9;
	--ticto-ob-title-outline: var(--red); //#f1c395;
	--ticto-ob-title-color: var(--white);
}
`;

export default GlobalStyle;
