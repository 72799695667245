import { parseCookies, setCookie } from 'nookies'
import { ReactNode, createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { api } from '../../services/api'
import { handleHTTPErrorMessage } from '../../utils/handleHTTPErrorMessage'
import { queryString } from '../../utils/queryString'
import { convertParamsToObject, getParameterByName, getPathFromUrl } from '../../utils/urlParams'
import { DecisionsProps, DiscountProps, FlowProps, FlowStepProps } from './types/Flow'

export interface FlowContextProps {
	isLoading: boolean;
	setIsLoading: (value: boolean) => void;
	findOne: (hash: string) => Promise<FlowProps>;
	findStep: (offerCode: string, stepOfferCode: string) => Promise<FlowStepProps>;

	decline: () => Promise<void>;
	getFlowUUID: () => string;
	getStepOfferCode: () => string;
	getMainOfferCode: () => Promise<string>;

	isUpsellDiscountModalOpen: boolean
	setIsUpsellDiscountModalOpen: (isOpen: boolean) => void
	handleRejectFlow: () => void

	purchases: string[]
	setPurchases: (value: string[]) => void
	flow: FlowProps,
	step: FlowStepProps,
	accept: DecisionsProps,
	reject: DecisionsProps,
	setUpsellDiscount: (discount: DiscountProps | null) => void
	upsellDiscount: DiscountProps | null,
	isDiscountAccepted: boolean,
	setIsDiscountAccepted: (value: boolean) => void
}

export interface FlowProviderProps {
	children: ReactNode;
}

const FlowContext = createContext({} as FlowContextProps);

export const FlowProvider = ({ children }: FlowProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [isSending, setIsSending] = useState(true);
	const [purchases, setPurchases] = useState<string[]>([])
	const [flow, setFlow] = useState<FlowProps>(null)
	const [step, setStep] = useState<FlowStepProps>(null)
	const [accept, setAccept] = useState<DecisionsProps>(null);
	const [reject, setReject] = useState<DecisionsProps>(null);
	const [isDiscountAccepted, setIsDiscountAccepted] = useState(false);
	const [isUpsellDiscountModalOpen, setIsUpsellDiscountModalOpen] = useState(false);
	const [upsellDiscount, setUpsellDiscount] = useState<DiscountProps | null>(null);

	const findOne = async (pk: string): Promise<FlowProps> => {
		setIsLoading(true);
		return await new Promise((resolve, reject) => {
			api.get<FlowProps>(`flow/${String(pk)}`)
				.then(async response => {
					if (!response.data?.pk) {
						resolve(null);
						return;
					}
					setFlow(response.data);
					resolve(response.data);
				})
				.catch(error => {
					toast.error(handleHTTPErrorMessage(error));
					reject(error);
				})
				.finally(() => setTimeout(() => setIsLoading(false), 1000));
		});
	};

	const findStep = async (offerCode: string, stepOfferCode: string): Promise<FlowStepProps> => {
		if (!offerCode || !stepOfferCode) return;

		let params = {
			fromMidas: true,
			upsell_code: stepOfferCode,
			rand: Math.random(),
			purchases: getParameterByName('purchases') ?? JSON.stringify(purchases),
		};

		setIsLoading(true);
		return await new Promise((resolve, reject) => {
			api.get<FlowStepProps>(`flow-step/${offerCode}${queryString(params)}`)
				.then(async response => {
					setStep(response.data);

					setAccept(response.data?.decisions?.accept);
					setReject(response.data?.decisions?.reject);

					resolve(response.data);
				})
				.catch(error => {
					toast.error(handleHTTPErrorMessage(error));
					reject(error);
				})
				.finally(() => setTimeout(() => setIsLoading(false), 1000));
		});
	};

	const getHash = () => {
		const fromURL = getParameterByName('hash');
		const hash = fromURL;

		return hash;
	};

	const handleRejectFlow = async () => {
		const reject = document.querySelectorAll('.ticto-refuse-button');
		let olderButtonText: string[] = [];

		for (let i = 0; i < reject.length; i++) {
			olderButtonText.push(reject[i].innerHTML);
			reject[i].innerHTML = 'Por favor, aguarde...';
		}

		const mainOfferCode = await getMainOfferCode();
		const stepOfferCode = getStepOfferCode();
		const step = await findStep(mainOfferCode, stepOfferCode);
		const stepReject = step?.decisions?.reject;
		const rejectDiscount = stepReject?.discount;

		if (rejectDiscount && !isDiscountAccepted) {
			setUpsellDiscount(rejectDiscount);
			setIsUpsellDiscountModalOpen(true);
		} else {
			decline();
		}
	}

	const decline = async () => {
		const reject = document.querySelectorAll('.ticto-refuse-button');
		let olderButtonText: string[] = [];
		for (let i = 0; i < reject.length; i++) {
			olderButtonText.push(reject[i].innerHTML);
			reject[i].innerHTML = 'Por favor, aguarde...';
		}

		let params: any = String(window.location.search).replace('?', '');
		let downsellURL: string = `${
			process.env.REACT_APP_CHECKOUT_URL
		}/thanks/${getHash()}?withoutFrame=true&${params}`;

		const mainOfferCode = await getMainOfferCode();
		const stepOfferCode = getStepOfferCode();
		const step = await findStep(mainOfferCode, stepOfferCode);
		const stepReject = step?.decisions?.reject;

		const purchases = getParameterByName('purchases')

		if (stepReject && stepReject.url) {
			let stepURL = getPathFromUrl(stepReject.url);
			let stepParams: any = String(stepReject.url).split('?')[1];
			stepParams = stepParams ? convertParamsToObject(stepParams) : {};

			let urlParams = convertParamsToObject(window.location.search);

			params = { ...urlParams, ...stepParams };

			params.currentOfferCode = step.decisions.reject.offer_code;
			params.purchases = purchases?.length > 0 ? purchases : getParameterByName('purchases');

			setCookie(undefined, `ticto@currentStep`,
				JSON.stringify(step.decisions.reject.offer_code),
				{
					maxAge: 60 * 60 * 24, // 24 hours
				}
			)

			downsellURL = `${stepURL}${queryString(params)}`;
		}

		// Retornando o texto do botão para o padrão anterior.
		for (let i = 0; i < reject.length; i++) {
			reject[i].innerHTML = olderButtonText[i];
		}

		setTimeout(() => {
			downsellURL.includes('withoutFrame')
				? (window.parent.location.href = downsellURL)
				: (window.location.href = downsellURL);

			setIsLoading(true);
		}, 500);
		return;
	};

	const getFlowUUID = () => {
		const byURL = getParameterByName('currentFlow');
		const byURL2 = getParameterByName('flow');

		let cookies = parseCookies();
		let flowCookie = cookies[`ticto@currentFlow`] && JSON.parse(cookies[`ticto@currentFlow`]);
		const byCookie = flowCookie?.pk;

		let byLocalStorage = localStorage.getItem('ticto@currentFlow');
		byLocalStorage = byLocalStorage && JSON.parse(byLocalStorage);

		return byURL ?? byURL2 ?? byCookie ?? byLocalStorage;
	};

	const getMainOfferCode = async (): Promise<string> => {
		return await new Promise(async (resolve, reject) => {
			const byURL = getParameterByName('currentFlowMainOffer');

			let cookies = parseCookies();
			let byCookie = cookies[`ticto@currentFlow`] && JSON.parse(cookies[`ticto@currentFlow`]);
			byCookie = byCookie?.offer_code;

			let byLocalStorage = localStorage.getItem('ticto@mainOfferCode');
			byLocalStorage = byLocalStorage && JSON.parse(byLocalStorage);

			if (!byURL && !byCookie && !byLocalStorage) {
				const flow = await findOne(getFlowUUID());
				resolve(flow.product.offers[0].code);
			}

			return resolve(byURL ?? byCookie ?? byLocalStorage);
		});
	};

	const getStepOfferCode = () => {
		const byURL = getParameterByName('currentOfferCode');

		let cookies = parseCookies();
		let byCookie = cookies[`ticto@currentStep`] && JSON.parse(cookies[`ticto@currentStep`]);

		let byLocalStorage = localStorage.getItem('ticto@currentOfferCode');
		byLocalStorage = byLocalStorage && JSON.parse(byLocalStorage);

		return byURL ?? byCookie ?? byLocalStorage;
	};

	const value = {
		isLoading,
		setIsLoading,

		findOne,
		findStep,
		decline,

		getFlowUUID,
		getStepOfferCode,
		getMainOfferCode,

		isUpsellDiscountModalOpen,
		setIsUpsellDiscountModalOpen,

		upsellDiscount,
		setUpsellDiscount,

		handleRejectFlow,

		purchases,
		setPurchases,

		flow,
		step,

		accept,
		reject,

		isDiscountAccepted,
		setIsDiscountAccepted
	}

	return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
};

export const useFlow = () => {
	const context = useContext(FlowContext);
	if (context === undefined) {
		throw new Error('useFlow está fora de FlowProvider.');
	}
	return context;
};
