import { Spinning } from 'components/Spinning';
import React from 'react';
import { ButtonAccept } from './ButtonAccept';

interface AcceptButtonProps {
	isSending: boolean
	isCalculating: boolean
	onClick: () => void
	text: string
}
const Accept: React.FC<AcceptButtonProps> = ({
	isSending, isCalculating, onClick, text = ''
}) => {
	return (
		<ButtonAccept
			disabled={isSending || isCalculating}
			onClick={onClick}
		>
			{isSending ? <Spinning /> : text}
		</ButtonAccept>
	)
}

export default Accept;
