import React from 'react';
import MidasCountdown from '../Countdown';
import { HeaderContainer } from './HeaderContainer';

const Header: React.FC = () => {
	return (
		<HeaderContainer>
			<MidasCountdown />
		</HeaderContainer>
	);
};

export default Header;
