import styled from 'styled-components';
export const CountdownElements = styled.ul`
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	position: relative !important;
	height: 50px !important;
	gap: 16px !important;
	padding: 0 18px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: bold !important;
	color: white !important;
	background: var(--ticto-green) !important;
	margin: 0 !important;

	&.completed {
		background: var(--ticto-red) !important;
	}
	li {
		position: relative !important;
		list-style: none !important;
		background: var(--red) !important;
		border-radius: 08px !important;
		text-align: center !important;
		h1,
		p {
			color: white !important;
		}

		p {
			font-size: 13px !important;
		}

		h1 {
			font-size: 15px !important;
		}

		&:not(:first-child) {
			&::before {
				content: ':' !important;
				position: absolute !important;
				font-size: 24px !important;
				left: -0.732px !important;
				top: 0.5px !important;
			}
		}
	}
`;
