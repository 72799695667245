import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Spinning } from '../../../Spinning';
import { PixCountdownElements } from './Countdown';

const PixCountdown = () => {
	const { t } = useTranslation('upsell');

	const renderer = ({ hours, minutes, seconds, completed, total }) => {
		return (
			<PixCountdownElements>
				<li>
					<Spinning /> {String(t('payment.waitingPayment'))}
					<span className='number'>{String(minutes).padStart(2, '0')}</span>:
					<span className='number'>{String(seconds).padStart(2, '0')}</span>
				</li>
			</PixCountdownElements>
		);
	};

	return <Countdown date={Date.now() + 15 * 60000} zeroPadTime={3} renderer={renderer} />;
};
export default React.memo(PixCountdown);
