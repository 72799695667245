export const Symbol: React.FC<any> = styles => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			x='0'
			y='0'
			enableBackground='new 0 0 226.8 226.8'
			version='1.1'
			viewBox='0 0 226.8 226.8'
			xmlSpace='preserve'
			className={styles.className}
		>
			<path d='M113.3 226.8h-94c-5.5 0-10.2-1.8-14-5.7-3.7-3.8-5.3-8.4-5.3-13.6V104.2v-85C0 13.1 2.2 8 7 4.2c3.6-3 7.8-4.2 12.5-4.2H207.4c6.2 0 11.4 2.1 15.4 7 3 3.6 4.2 7.8 4.2 12.4v188c0 5.3-1.5 9.9-5.3 13.8-3.9 4-8.6 5.7-14.1 5.7h-79.4c-5.1-.1-10-.1-14.9-.1zm-66-146h70.8c1.8 0 3.5.1 4.3 2.1.9 2-.3 3.3-1.6 4.6l-23.1 23.1c-2.2 2.2-3.3 4.7-3.3 7.8v64.7c0 1.3.3 1.7 1.7 1.7 11.3-.1 22.7 0 34 0 2.1 0 2.1 0 2.1-2.1V82.9c0-2.1 0-2.1 2.2-2.1h43.3c.6 0 1.1 0 1.7-.1 0-.4.1-.6.1-.8V53.6c0-1.3-.7-1.2-1.5-1.2H48.7c-.5 0-.9 0-1.5.1.1 9.5.1 18.8.1 28.3z'></path>
		</svg>
	);
};
