import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { api } from 'services/api';

const resources = {
	'pt-BR': {
		upsell: require('./pt/upsell/translate.json'),
		handleHTTPErrorMessage: require('./pt/handleHTTPErrorMessage/translate.json'),
	},
	'en-US': {
		upsell: require('./en/upsell/translate.json'),
		handleHTTPErrorMessage: require('./en/handleHTTPErrorMessage/translate.json'),
	},
	es: {
		upsell: require('./es/upsell/translate.json'),
		handleHTTPErrorMessage: require('./es/handleHTTPErrorMessage/translate.json'),
	},
};

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'pt-BR',
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ['cookie', 'querystring', 'navigator', 'localStorage', 'htmlTag', 'path', 'subdomain'],
			caches: ['cookie'],
		},
	}, () => {
		api.defaults.headers['Accept-Language'] = i18next.language;
	});

export default i18next;
