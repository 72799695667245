import { Symbol } from 'components/Symbol';
import { useOneClick } from 'contexts/OneClickBuyContext';
import { isCreditCard } from 'helpers/paymentMethod';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertCentsToBRL } from 'utils/currency';
import InstallmentsSelect from '../../InstallmentsSelect';
import { FromPriceContainer, Image, Info, ProductContainer } from './Product';
const Price: React.FC = () => {
	const { t } = useTranslation('upsell');
	const { offer, itemsAmount, installments, fromPaymentMethod, couponValue, currency } = useOneClick();
	return (
		<>
			<strong>{
				(isCreditCard(fromPaymentMethod) &&
					installments?.labels?.length > 1) &&
				<small>{String(t('installmentsLabels.or'))} </small>} {convertCentsToBRL(itemsAmount - couponValue, currency)} <small>{String(t('installmentsLabels.inCash'))}</small>
			</strong>
			<>
				{offer?.subscription?.first_charge_price && (
					<small className='secondPrice'>
						{String(t('subscription.fromSecondCharge'))}
						<b>{convertCentsToBRL(offer.original_price, currency)}</b>
					</small>
				)}
			</>
		</>
	);
};

const FromPrice: React.FC = () => {
	const { itemsAmount, couponValue, currency } = useOneClick();
	const { t } = useTranslation('upsell');

	if (!couponValue) return null

	return (
		<FromPriceContainer>
			<small>{String(t('bumps.from'))}</small>{' '}
			<strong>{convertCentsToBRL(itemsAmount, currency)}</strong>{' '}
			<small>{String(t('bumps.for'))}</small>
		</FromPriceContainer>
	)
}

const Product: React.FC = () => {
	const { product, offer, fromPaymentMethod } = useOneClick();

	return (
		<ProductContainer>
			<Image>
				{product?.cover_picture ? <img src={product.cover_picture} alt={product.name} /> : <Symbol />}
			</Image>
			<Info>
				<h1>{product.name}</h1>
				{offer?.product_checkout_description ? (
					<small className='offer'>{offer?.product_checkout_description}</small>
				) : null}
				<FromPrice />
				{isCreditCard(fromPaymentMethod) && <InstallmentsSelect />}
				<Price />
			</Info>
		</ProductContainer>
	);
};

export default Product;
