import Accept from 'components/Button/Accept';
import Reject from 'components/Button/Reject';
import { useFlow } from 'contexts/FlowContext';
import { useOneClick } from 'contexts/OneClickBuyContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from './Container';

const UpsellButtons: React.FC = () => {
	const { isSending, isCalculating, decline, pay } = useOneClick();
	const { flow, handleRejectFlow } = useFlow();
	const { t } = useTranslation('upsell');

	return (
		<ButtonsContainer>
			<Accept isCalculating={isCalculating} isSending={isSending} onClick={pay} text={t('actions.buy')} />
			<Reject
				onClick={() => {
					flow ? handleRejectFlow() : decline();
				}}
				isCalculating={isCalculating}
				isSending={isSending}
			/>
		</ButtonsContainer>
	);
};

export default UpsellButtons;
