import { memo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

interface ClipboardProps {
	text: string;
	clipboard: {
		value: string;
		copied: boolean;
	};
	setClipboard: (clipboard) => void;
}

const Clipboard: React.FC<ClipboardProps> = ({ text, clipboard, setClipboard }) => {
	const { t } = useTranslation('upsell');

	return (
		<>
			<CopyToClipboard
				text={text}
				onCopy={() => {
					setClipboard({ value: text, copied: true });
				}}
			>
				<span
					data-tooltip-id={text}
					data-tooltip-content={
						clipboard.copied ? String(t('clipboard.copied')) : String(t('clipboard.clickToCopy'))
					}
				>
					{text}
					<Tooltip id={text} />
				</span>
			</CopyToClipboard>
		</>
	);
};
export default memo(Clipboard);
