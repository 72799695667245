import React from 'react';
const TictoLogo: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			x='0'
			y='0'
			enableBackground='new 0 0 127.3 37.6'
			version='1.1'
			viewBox='0 0 127.3 37.6'
			xmlSpace='preserve'
		>
			<path d='M67.1 12.6h3.5v17.8h-3.5V12.6zM87.3 24.1c-.2 1-.6 1.9-1.3 2.6-.9.9-2 1.3-3.5 1.3-1.6 0-2.9-.6-3.9-1.8-1-1.2-1.4-2.9-1.4-4.9 0-1.9.5-3.5 1.4-4.6s2.3-1.7 3.9-1.7c1.5 0 2.7.4 3.6 1.3.7.7 1.1 1.5 1.3 2.6h3.5c-.2-1.2-.5-2.2-1.1-3.1-.7-1.2-1.7-2.1-3-2.7-1.3-.6-2.7-.9-4.3-.9-1.7 0-3.3.4-4.6 1.1-1.3.8-2.4 1.8-3.1 3.3-.7 1.4-1.1 3.1-1.1 4.9 0 2 .4 3.7 1.1 5.1.8 1.4 1.8 2.5 3.1 3.3 1.3.8 2.9 1.2 4.6 1.2 1.6 0 3-.3 4.3-.9 1.3-.6 2.3-1.5 3-2.7.6-.9.9-2 1.1-3.2h-3.6zM63.6 14.9v-2.4h-4.9V8.8h-1.2l-1.7 2.9c-.4.6-.9 1.1-1.6 1.3l-2 .7V15h3v10.8c0 1.3.2 2.3.6 3 .4.7 1 1.2 1.8 1.4.8.2 2 .4 3.5.4.7 0 1.6 0 2.4-.1v-2.9c-.9.1-1.7.1-2.3.1-1 0-1.7-.1-2.1-.4-.4-.3-.6-.9-.6-1.8V14.9h5.1zM106.2 26.4c-.9-1.4-1.3-3.1-1.3-5s.4-3.5 1.3-4.9c.9-1.4 2-2.5 3.4-3.3 1.4-.8 3-1.2 4.7-1.2 1.7 0 3.2.4 4.7 1.2 1.4.8 2.6 1.9 3.4 3.3.9 1.4 1.3 3.1 1.3 4.9 0 1.9-.4 3.6-1.3 5-.8 1.4-2 2.6-3.4 3.3-1.4.8-3 1.2-4.7 1.2-1.7 0-3.3-.4-4.7-1.2-1.4-.8-2.6-1.9-3.4-3.3zm14-5c0-2-.5-3.6-1.6-4.8-1.1-1.1-2.5-1.7-4.3-1.7-1.8 0-3.2.6-4.3 1.7-1.1 1.1-1.6 2.7-1.6 4.8 0 2.1.5 3.7 1.6 4.9 1.1 1.2 2.5 1.8 4.3 1.8 1.8 0 3.2-.6 4.3-1.8 1.1-1.2 1.6-2.9 1.6-4.9z'></path>
			<circle fill='#FFFFFF' cx='68.8' cy='8.8' r='2'></circle>
			<path d='M126 13.9c-.1 0-.1-.1-.2-.1.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3-.1-.1-.2-.1-.4-.1h-.7v1.5h.3V14H125.9s.1 0 .1.1v.1l.1.4h.3l-.1-.5c-.2-.1-.3-.2-.3-.2zm-.4-.1h-.4v-.5h.4c.1 0 .2 0 .2.1.1 0 .1.1.1.2s0 .1-.1.2h-.2z'></path>
			<path d='M125.5 12c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8zm0 3.3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM103.4 14.9v-2.4h-4.9V8.8h-1.2l-1.7 2.9c-.4.6-.9 1.1-1.6 1.3l-2 .7V15h3v10.8c0 1.3.2 2.3.6 3 .4.7 1 1.2 1.8 1.4.8.2 2 .4 3.5.4.7 0 1.6 0 2.4-.1v-2.9c-.9.1-1.7.1-2.3.1-1 0-1.7-.1-2.1-.4-.4-.3-.6-.9-.6-1.8V14.9h5.1z'></path>
			<g>
				<path d='M33.6.3h-31c-1.7 0-3 1.4-3 3v30.9c0 1.7 1.4 3 3 3h30.9c1.7 0 3-1.4 3-3V3.3c.1-1.6-1.3-3-2.9-3zm-4.7 13.2h-7.7v17H15v-11c0-.4.2-.8.5-1.1l4-4c.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4-.1-.1-.2-.2-.4-.2H7.3V8.9h21.6v4.6z'></path>
			</g>
		</svg>
	);
};

export default TictoLogo;
