import { useFlow } from 'contexts/FlowContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TictoLogo from '../../TictoLogo';
import DiscountHeader from '../Header/Discount';
import { Footer } from '../styles/Footer';
import { ModalContainer } from '../styles/ModalContainer';
import { DiscountDecisionContainer } from '../styles/UpsellContainer';
import { Content } from './content';

const UpsellDiscountModal: React.FC = () => {
	const { isUpsellDiscountModalOpen } = useFlow();
	const { t } = useTranslation('upsell');

	if (!isUpsellDiscountModalOpen) return null;

	return (
		<ModalContainer>
			<DiscountDecisionContainer>
				<DiscountHeader />
				<Content />
			</DiscountDecisionContainer>
			<Footer>
				<small>{t('footer.securityText')}</small>
				<TictoLogo />
			</Footer>
		</ModalContainer>
	);
};

export default UpsellDiscountModal;
