import { useFlow } from 'contexts/FlowContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DiscountHeaderContainer } from './HeaderContainer';

const DiscountHeader: React.FC = () => {
	const { upsellDiscount } = useFlow();
	const { t } = useTranslation('upsell');

	return <DiscountHeaderContainer>{upsellDiscount.title ?? `🎁 ${t('title.specialOffer')}`}</DiscountHeaderContainer>;
};

export default DiscountHeader;
