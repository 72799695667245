import Logotype from '../TictoLogo/Logotype';
import { Loader, Loading, Spinning } from './Spinning';
interface TictoLoaderProps {
	fullPage?: boolean;
}
export const TictoLoader = ({ fullPage }: TictoLoaderProps) => {
	return (
		<Spinning>
			<Loading>
				<Logotype />
				<Loader />
			</Loading>
		</Spinning>
	);
};
