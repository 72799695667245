import { useTranslation } from 'react-i18next';
import { useOneClick } from '../../../contexts/OneClickBuyContext';
import { TransactionDetailsContainer } from './TransactionDetailsContainer';

const TransactionDetails: React.FC = () => {
	const { t } = useTranslation('upsell');
	const { paymentResponse, actualTransaction } = useOneClick();

	return (
		<TransactionDetailsContainer>
			<p>
				<b>{String(t('transactionDetails.product'))} </b> {paymentResponse.items[0].product.name}
			</p>
			<p>
				<b>{String(t('transactionDetails.orderId'))} </b> {String(paymentResponse.id).padStart(8, '0')}
			</p>
			<p>
				<b>{String(t('transactionDetails.orderHash'))} </b>
				<span>{paymentResponse.hash}</span>
			</p>
			<p>
				<b>{String(t('transactionDetails.transactionHash'))} </b>
				<span>{actualTransaction.hash}</span>
			</p>
		</TransactionDetailsContainer>
	);
};

export default TransactionDetails;
