import React from 'react'
import { useFlow } from '../../contexts/FlowContext'
import { useOneClick } from '../../contexts/OneClickBuyContext'
import { TictoLoader } from '../TictoLoader'
import TictoLogo from '../TictoLogo'
import PixPayment from '../Upsell/PixPayment'
import Bumps from './Bumps'
import UpsellButtons from './Buttons/Upsell'
import { CardPicker } from './CreditCardPicker'
import Header from './Header'
import Error from './Messages/Error'
import Success from './Messages/Success'
import Product from './Product'
import { Footer } from './styles/Footer'
import { ModalContainer } from './styles/ModalContainer'
import { UpsellContainer } from './styles/UpsellContainer'
import { useTranslation } from 'react-i18next'

const Upsell: React.FC = () => {
	const {
		isModalOpen,
		isCalculating,
		paymentResponse,
		affiliates,
		paymentError,
		isLoading: isLoadingOneClick,
		error,
		isWaitingPixPayment,
	} = useOneClick();

	const { isLoading: isLoadingFlow } = useFlow();
	const isProduction = process.env.REACT_APP_MIDAS_ENVIRONMENT === 'production';
	const isLoading = isLoadingOneClick || isCalculating || isLoadingFlow;
	const hasError = error || paymentError;
	const { t } = useTranslation('upsell');

	if (!isModalOpen) return null;

	return (
		<ModalContainer>
			<UpsellContainer>
				<>
					{isLoading ? (
						<TictoLoader />
					) : isWaitingPixPayment ? (
						<PixPayment />
					) : paymentResponse ? (
						<Success />
					) : hasError ? (
						<Error />
					) : (
						<>
							<Header />
							<Product />
							<Bumps />
							<CardPicker />
							<UpsellButtons />
						</>
					)}
				</>
			</UpsellContainer>
			<Footer>
				<small> {String(t('footer.securityText'))} </small>
				<TictoLogo />
				{!isProduction && affiliates?.length > 0 && (
					<small>Afiliados: {affiliates?.map(aff => aff.code)?.join(', ')}</small>
				)}
			</Footer>
		</ModalContainer>
	);
};

export default Upsell;
