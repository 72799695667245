import { useOneClick } from "contexts/OneClickBuyContext";
import { CardProps } from "contexts/OneClickBuyContext/types/Cards";
import { isCreditCard } from 'helpers/paymentMethod';
import { CardBrand, CardFlag, CardItem, CardList, Checkbox, Container } from "./styles";
import { useTranslation } from "react-i18next";

export const CardPicker: React.FC = () => {
	const { cards, setSelectedCard, selectedCard, fromPaymentMethod } = useOneClick();
	const { t } = useTranslation('upsell');

	const handleSelectCard = (card: CardProps) => {
		if (selectedCard?.code === card.code) return;
		setSelectedCard(card);
	};

	if (cards.length <= 1 || !isCreditCard(fromPaymentMethod)) return null;

	return (
		<Container>
			<p>{String(t('cards.selectCard'))}</p>
			<CardList>
				{cards.map((card, index) => (
					<CardItem key={index} onClick={() => handleSelectCard(card)}>
						<CardFlag>
							<img
								src={`${process.env.REACT_APP_MIDAS_HOST}/payment_flags/${card.brand}.svg`}
								alt={card.brand}
							/>
						</CardFlag>
						<CardBrand>
							<strong>**** **** **** {card.last4}</strong>
							<p>
								{card.brand} - {card.expiration_date}
							</p>
						</CardBrand>
						<Checkbox checked={selectedCard?.code === card.code} onChange={() => { }} />
					</CardItem>
				))}
			</CardList>
		</Container>
	);
};
