import styled from 'styled-components';
export const ButtonSuccess = styled.button`
	border-radius: 08px !important;
	height: 40px !important;
	cursor: pointer !important;
	background: var(--ticto-green) !important;
	color: white !important;
	transition: all 0.2s !important;
	padding: 0 18px !important;
	border: 2px solid !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	font-weight: 500 !important;
	transition: all 0.2s !important;
	font-size: 15px !important;

	svg {
		max-width: 3rem;
	}

	&:hover {
		transform: scale(1.05) !important;
		filter: brightness(1.1) !important;
	}

	&[disabled] {
		filter: grayscale(1) !important;
		cursor: not-allowed !important;
	}
`;
