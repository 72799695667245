import styled from 'styled-components';

export const PixPaymentContainer = styled.div`
	border-top-right-radius: inherit;
	border-top-left-radius: inherit;
`

export const Header = styled.div`
	border-top-right-radius: inherit;
	border-top-left-radius: inherit;
	background-color: #edf2f7;
	padding: 0.5rem;
`
export const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	gap: 1rem;

		h3 {
			width: auto;
			color: #000000 !important;
		}

	svg {
		color: #009d68;
		width: 25px !important;
		height: 25px !important;
		width: auto;
	}
`
export const Content = styled.div`
	padding: 1rem;
`
