import styled from 'styled-components';

export const Container = styled.div`
	background: var(--ticto-background);
	font-family: 'Inter', sans-serif;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;

	.box {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: center;
		justify-content: center;

		background: var(--ticto-shape);
		color: var(--ticto-primary);
		padding: 60px;
		border-radius: 16px;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	}
`;
