import DiscountButtons from "components/Upsell/Buttons/Discount";
import { useFlow } from "contexts/FlowContext";
import { useOneClick } from "contexts/OneClickBuyContext";
import { convertCentsToBRL } from "utils/currency";
import { Badge, Container, Discount, Popup, Title } from "./styles";

export const Content = () => {
	const { upsellDiscount } = useFlow();
	const { currency } = useOneClick();

	return (
		<Container>
			<Popup>
				<Badge >
					<img src={`${process.env.REACT_APP_MIDAS_HOST}/assets/popup-badge-${upsellDiscount.type}.svg`} alt='popup' />
					<Discount
						type={upsellDiscount.type}
					>
						{upsellDiscount.type === 'fixed'
							? <>
								{convertCentsToBRL(upsellDiscount.fixed, currency)}
								<br />
								OFF
							</>
							: upsellDiscount.percentage
						}
					</Discount>
				</Badge>
				<div>
					<Title>{upsellDiscount.title}</Title>
					<p>{upsellDiscount.description}</p>
				</div>
			</Popup >
			<DiscountButtons />
		</Container>
	)
}
