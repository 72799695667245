import styled from 'styled-components';

export const OrderBump = styled.div`
	max-height: 250px;
	overflow: auto;
	> strong {
		display: block !important;
		padding: 16px 0 !important;
		margin: 0 auto !important;
		width: fit-content !important;
		text-align: center !important;
		color: var(--ticto-primary) !important;
	}
	.bump {
		display: grid !important;
		grid-template-columns: 1fr 130px !important;
		gap: 16px !important;
		background: var(--ticto-ob-bg) !important;
		border: 2px dashed var(--ticto-ob-outline) !important;
		border-radius: 08px !important;
		align-items: center !important;
		padding: 16px !important;
		margin-top: 08px !important;
		cursor: pointer !important;
		transition: all 0.2s ease-in-out !important;
		margin: 0 16px !important;
		width: calc(100% - 32px) !important;

		& + .bump {
			margin-top: 08px !important;
		}

		&:hover {
			transform: scale(1.03) !important;
		}

		.details {
			display: flex !important;
			gap: 16px !important;
			width: 100% !important;
			align-items: center !important;
			cursor: pointer !important;
			transition: all 0.2s ease-in-out !important;

			.arrow {
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
				border-radius: 08px !important;
				width: fit-content;
				svg {
					width: 32px !important;
					height: 32px !important;
					color: var(--ticto-red) !important;
				}
			}

			.check {
				width: fit-content;
				input {
					width: 16px !important;
					height: 16px !important;
				}
			}

			.cover {
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
				background: var(--ticto-primary) !important;
				border-radius: 08px !important;
				width: 50px !important;
				aspect-ratio: 1 / 1 !important;

				svg {
					margin: 16px !important;
					color: white !important;
					path {
						fill: white !important;
						border: white !important;
					}
				}

				img {
					border-radius: 08px !important;
					width: 50px !important;
					aspect-ratio: 1 / 1 !important;
				}
			}

			.info {
				width: auto !important;
				strong {
					font-size: 14px !important;
				}
			}
		}
		.pricing {
			text-align: right !important;
			justify-self: flex-end !important;
			* {
				font-size: 14px !important;
			}
			.fromPrice {
				color: var(--ticto-red) !important;
				text-decoration: line-through !important;
			}
			.price {
				color: var(--ticto-success) !important;
				display: block !important;
			}
		}
	}
`;
