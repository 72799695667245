import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonReject } from './ButtonReject';

interface RejectButtonProps {
	isSending: boolean;
	isCalculating: boolean;
	onClick: () => void;
	text?: string;
}

const Reject: React.FC<RejectButtonProps> = ({ isCalculating, isSending, onClick, text = '' }) => {
	const { t } = useTranslation('upsell');

	if (text === '') {
		text = t('actions.noThanks');
	}

	return (
		<ButtonReject disabled={isSending || isCalculating} onClick={onClick}>
			{text}
		</ButtonReject>
	);
};

export default Reject;
