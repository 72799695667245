const _encode = (v: any) => {
	if (v === undefined || v === null) return '';
	return encodeURIComponent(v);
}

export const queryString = (params: any) => {
	const query = Object.keys(params)
			.filter(key => key !== 'cupom')
			.map(key => _encode(key) + '=' + _encode(params[key]))
			.join('&');
	return query.length > 0 ? `?${query}` : '';
}
