import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdOutlineContentCopy } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { useOneClick } from '../../../../contexts/OneClickBuyContext';
import Clipboard from '../../../Clipboard';
import { Symbol } from '../../../Symbol';
import { ButtonSuccess } from '../../styles/ButtonSuccess';
import { PixCodesContainer } from './PixCodesContainer';

const PixCodes: React.FC = () => {
	const { t } = useTranslation('upsell');
	const { actualTransaction } = useOneClick();
	const [clipboard, setClipboard] = useState({
		value: '',
		copied: false,
	});

	return (
		<PixCodesContainer>
			<div className='pixCode'>
				<Clipboard text={actualTransaction?.pix_qr_code} clipboard={clipboard} setClipboard={setClipboard} />
			</div>
			<CopyToClipboard text={actualTransaction?.pix_qr_code}>
				<ButtonSuccess onClick={null}>
					{clipboard.copied ? (
						<>
							{String(t('clipboard.copied'))} <BsCheck2Circle />
						</>
					) : (
						<>
							{String(t('clipboard.copyPix'))} <MdOutlineContentCopy />
						</>
					)}
				</ButtonSuccess>
			</CopyToClipboard>
			<div className='pixQrCode'>
				<QRCode value={actualTransaction?.pix_qr_code} />
				<div className='logo'>
					<Symbol />
				</div>
			</div>
		</PixCodesContainer>
	);
};

export default PixCodes;
