import { AxiosError } from 'axios';
import i18next from 'i18next';

export interface ErrorMessage {
	response: {
		data: {
			message: string;
		};
	};
}

const handleHTTPErrorMessage = (error: AxiosError): string => {
	let message = '';
	const { t } = i18next;
	i18next.setDefaultNamespace('handleHTTPErrorMessage');

	try {
		if (
			typeof error === undefined &&
			typeof error?.response === undefined &&
			typeof error?.response?.status === undefined
		) {
			message = (error as ErrorMessage).response.data.message ?? t('undefinedStatusErrorMessage');
		} else if (typeof error.response.data === 'string') {
			message = error.response.data;
		} else {
			if (error.response.data?.errors && error.response.data.errors[0] !== '') {
				const { errors } = error.response.data;
				Object.keys(errors).map((error, index) => (message += `${errors[error]} \n\n`));
			} else {
				switch (error.response.status) {
					case 301:
						message = t('301');
						break;
					case 302:
						message = t('302');
						break;
					case 303:
						message = t('303');
						break;
					case 304:
						message = t('304');
						break;
					case 305:
						message = t('305');
						break;
					case 307:
						message = t('307');
						break;
					case 308:
						message = t('308');
						break;
					case 400:
						message = t('400');
						break;
					case 401:
						message = t('401');
						break;
					case 402:
						message = t('402');
						break;
					case 403:
						message = t('403');
						break;
					case 404:
						message = t('404');
						break;
					case 405:
						message = t('405');
						break;
					case 406:
						message = t('406');
						break;
					case 407:
						message = t('407');
						break;
					case 408:
						message = t('408');
						break;
					case 409:
						message = t('409');
						break;
					case 410:
						message = t('410');
						break;
					case 411:
						message = t('411');
						break;
					case 412:
						message = t('412');
						break;
					case 413:
						message = t('413');
						break;
					case 414:
						message = t('414');
						break;
					case 415:
						message = t('415');
						break;
					case 416:
						message = t('416');
						break;
					case 417:
						message = t('417');
						break;
					case 418:
						message = t('418');
						break;
					case 419:
						message = t('419');
						break;
					case 420:
						message = t('420');
						break;
					case 421:
						message = t('421');
						break;
					case 422:
						message = t('422');
						break;
					case 423:
						message = t('423');
						break;
					case 424:
						message = t('424');
						break;
					case 428:
						message = t('428');
						break;
					case 429:
						message = t('429');
						break;
					case 431:
						message = t('431');
						break;
					case 451:
						message = t('451');
						break;
					case 500:
						message = t('500');
						break;
					case 501:
						message = t('5001');
						break;
					case 502:
						message = t('502');
						break;
					case 503:
						message = t('503');
						break;
					case 504:
						message = t('504');
						break;
					case 505:
						message = t('505');
						break;
					case 507:
						message = t('507');
						break;
					case 511:
						message = t('511');
						break;
				}
			}
		}
	} catch (e) {
		message = t('catch');
	}

	return message;
};

export { handleHTTPErrorMessage };
