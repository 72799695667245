import styled from "styled-components";

const popupBgUrl = process.env.REACT_APP_MIDAS_HOST + '/assets/bg-popup.svg';

export const Container = styled.div`
	background: ${`url(${popupBgUrl}) var(--ticto-shape) center center`};
	background-size: cover;
	min-height: 300px;
	border-radius: 0.8rem;
`;

export const Popup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	gap: 0.5rem;
	text-align: center;
`;

export const Title = styled.h1`
	font-size: 2rem;
	font-weight: bold;
`;

export const Badge = styled.div`
	position: relative;

	img {
		width: 300px;
	}
`;

export const Discount = styled.h1<{ type: 'fixed' | 'percentage' }>`
	position: absolute;
	font-weight: 800;
	top: ${({ type }) => type === 'fixed' ? '2rem' : '0'};
	left: ${({ type }) => type === 'fixed' ? '0' : '-3.5rem'};
	font-size: ${({ type }) => type === 'fixed' ? '3rem' : '7rem'};
	line-height: 50px;
	color: var(--ticto-red);
`;
