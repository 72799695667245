import styled from 'styled-components';
import { useOneClick } from '../../contexts/OneClickBuyContext';
import Select from '../Select';
import { Loader } from '../TictoLoader/Spinning';

interface InstallmentsSelectProps {
	split_installment_value?: number;
	setInstallment?: (totalAmountWithInterest: any) => void;
}

const SpinningContainer = styled.div`
	width: calc(100% - 32px);
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: flex-start;
	background: var(--background);
	padding: 8px 0;
	margin: 0;
	border-radius: 08px;

	svg {
		color: var(--primary);
	}
`;

const InstallmentsSelect = ({ split_installment_value, setInstallment }: InstallmentsSelectProps) => {
	const { offer, installments, changeInstallmentOption, isCalculating, selectedInstallment } = useOneClick();

	if (isCalculating || !selectedInstallment?.value)
		return (
			<SpinningContainer>
				{' '}
				<Loader />{' '}
			</SpinningContainer>
		);

	if (!selectedInstallment) return <h1>Ocorreu um erro</h1>;

	if (installments.labels.length === 1) return null;

	if (offer.allow_credit_card_installments) {
		return (
			<Select
				defaultValue={selectedInstallment.value}
				onChange={e => changeInstallmentOption(e.target.value)}
				options={installments.labels}
				name='installments'
				label='Parcelamento'
			/>
		);
	}
};

export default InstallmentsSelect;
