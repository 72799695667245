import Accept from 'components/Button/Accept';
import Reject from 'components/Button/Reject';
import { useFlow } from 'contexts/FlowContext';
import { useOneClick } from 'contexts/OneClickBuyContext';
import React from 'react';
import { ButtonsContainer } from './Container';
import { useTranslation } from 'react-i18next';

const DiscountButtons: React.FC = () => {
	const { t } = useTranslation('upsell');
	const { decline, handleAceptDiscount } = useOneClick();

	const { flow, decline: declineWithFlow } = useFlow();

	return (
		<ButtonsContainer>
			<Accept
				isCalculating={false}
				isSending={false}
				onClick={() => handleAceptDiscount()}
				text={t('actions.getDiscount')}
			/>
			<Reject
				onClick={() => {
					flow ? declineWithFlow() : decline();
				}}
				isCalculating={false}
				isSending={false}
			/>
		</ButtonsContainer>
	);
};

export default DiscountButtons;
