import styled from 'styled-components';

const ModalContainer = styled.div`
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100%;
	max-width: 500px !important;
	background: var(--ticto-shape) !important;
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
	border-radius: 16px !important;
	animation: open 0.3s !important;

	@media (max-width: 576px) {
		max-width: 90% !important;
	}

	* {
		width: 100%;
	}

	@keyframes open {
		from {
			transform: scale(0);
		}

		to {
			transform: scale(1);
		}
	}

	@keyframes close {
		from {
			transform: scale(1);
		}

		to {
			transform: scale(0);
			display: none;
		}
	}
`

export const UpsellContainer = styled(ModalContainer)``;

export const DiscountDecisionContainer = styled(ModalContainer)`
	text-align: center;
	p {
		font-size: 1rem;
		font-weight: 500;
	}
`;
