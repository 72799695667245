import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { CountdownElements } from './Countdown';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const MidasCountdown = () => {
	const { t } = useTranslation('upsell');

	const renderer = ({ hours, minutes, seconds, completed, total }) => {
		if (completed) {
			return (
				<CountdownElements className='completed'>
					<li>
						<h1>{String(t('title.countdownCompleted'))}</h1>
					</li>
				</CountdownElements>
			);
		}
		return (
			<CountdownElements>
				<li>
					<h1>
						{String(t('title.expiresIn'))} {String(seconds).padStart(2, '0')} {String(t('title.seconds'))}
					</h1>
				</li>
			</CountdownElements>
		);
	};
	return <Countdown date={Date.now() + 1 * 60000} zeroPadTime={3} renderer={renderer} />;
};
export default React.memo(MidasCountdown);
